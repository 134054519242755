import { HashRouter, Routes, Route, Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        className="container-fluid bg-dark text-light footer   wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Address</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>#48 Triumph Tower
                MG Road, Haridevpura Mahadevpur Shanthala Nagar, Bangalore,
                Karnataka 560001
              </p>
              <p className="mb-1">
                <i className="fa fa-phone-alt me-3"></i> +91-8217307139
              </p>
              <p className="mb-1">
                <i className="fa fa-envelope me-3"></i>support@artoies.com
              </p>

              <div className="d-inline-flex align-items-center mt-4 ">
                <a
                  className="btn btn-sm-square bg-white text-primary me-1"
                  href="https://www.facebook.com/artoies/"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-sm-square bg-white text-primary me-1"
                  href="https://x.com/myartoies"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-sm-square bg-white text-primary me-1"
                  href="https://in.linkedin.com/company/artoies-interational-private-limited  "
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  className="btn btn-sm-square bg-white text-primary me-0"
                  href="https://www.instagram.com/artoies/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Opening Hours</h4>
              <h6 className="text-light">Monday - Friday:</h6>
              <p className="mb-4">09.00 AM - 09.00 PM</p>
              <h6 className="text-light">Saturday - Sunday:</h6>
              <p className="mb-0">09.00 AM - 06.00 PM</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light ">Services</h4>
              <div className="btn btn-link text-light" href="">
                Diagnostic Test
              </div>
              <div className="btn btn-link text-light" href="">
                Engine Servicing
              </div>
              <div className="btn btn-link text-light" href="">
                Tires Replacement
              </div>
              <div className="btn btn-link text-light" href="">
                Oil Changing
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Offers</h4>
              <p>Please signup and stay updated on new offers</p>
              <div
                className="position-relative mx-auto"
                style={{ maxwidth: "500px" }}
              >
                <input
                  className="form-control border-0 w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>
            </div>
          </div>{" "}
          <div
            className="text-center p-3"
            style={{ backgroundcolor: "rgba(0, 0, 0, 0.05)" }}
          >
            © Copyrights 2021-2024
            <p className="text-reset fw-bold" href="https://artoies.com/">
              Artoies International Pvt Ltd (All Rights Reserved)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
