import whatsapp from "../icon/images/whatsapp.png.png";

const Whatsapp = () => {
  return (
    <>
      <div>
        <a href="https://wa.me/918217307139" target="_blank">
          <img
            src={whatsapp}
            style={{
              width: "80px",
              position: "fixed",
              bottom: "40px",
              right: "20px",
            }}
          />
        </a>
      </div>
    </>
  );
};

export default Whatsapp;
