import img2 from "../icon/images/img2.png";
import battery from "../icon/images/eco-battery.gif";
import oil from "../icon/images/engine.gif";
import pull from "../icon/images/tires.gif";
import maintaineance from "../icon/images/steering-wheel.gif";
import engine from "../icon/images/mainengine.gif";
import tyre from "../icon/images/tire.gif";

const Services = () => {
  return (
    <>
      <section className="ftco-section ">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3 pt-4">
            <div className="col-md-7 heading-section text-center ftco-animate">
              <span className="subheading text-secondary">
                We offer Services
              </span>
              <h2 className="text-primary">Our car services</h2>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-4 services ftco-animate">
              <div className="d-block d-flex">
                <div className="icon d-flex justify-content-center align-items-center">
                  {/* <i className="flaticon-car-service text-primary"></i>
                   */}
                  <img src={oil} width="50%" />
                </div>
                <div className="media-body pl-3">
                  <h3 className="heading">Oil change</h3>
                  <p>
                    Keep your engine running smoothly with our quick and
                    efficient oil change service. Enjoy enhanced —schedule your
                    appointment today!
                  </p>
                  {/* <p>
                    <a href="#" className="btn-custom">
                      Read more
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="d-block d-flex">
                <div className="icon d-flex justify-content-center align-items-center">
                  {/* <span className="flaticon-tyre text-primary"></span> */}
                  <img src={tyre} width="50%" />
                </div>
                <div className="media-body pl-3 mt-3">
                  <h3 className="heading">Tyre Change</h3>
                  <p>
                    Revitalize your ride with our quick and reliable tyre change
                    service! Drive safer and smoother with premium tyres
                    expertly fitted by our skilled technicians
                  </p>
                  {/* <p>
                    <a href="#" className="btn-custom">
                      Read more
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 services ftco-animate">
              <div className="d-block d-flex">
                <div className="icon d-flex justify-content-center align-items-center">
                  {/* <span className="flaticon-battery text-primary"></span>
                   */}
                  <img src={battery} width="50%" />
                </div>
                <div className="media-body pl-3">
                  <h3 className="heading">Batteries</h3>
                  <p>
                    Power up your ride with our high-performance car
                    batteries-engineered for durability-reliability,Enjoy
                    longer life.
                  </p>
                  {/* <p>
                    <a href="#" className="btn-custom">
                      Read more
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="d-block d-flex">
                <div className="icon d-flex justify-content-center align-items-center">
                  {/* <span className="flaticon-car-engine text-primary"></span> */}
                  <img src={engine} width="50%" />
                </div>
                <div className="media-body pl-3 mt-3">
                  <h3 className="heading">Engine Repair</h3>
                  <p>
                    Revive your ride with our expert engine repair—boost
                    performance and longevity! Get back on the road with
                    confidence and peace of mind
                  </p>
                  {/* <p>
                    <a href="#" className="btn-custom">
                      Read more
                    </a>
                  </p> */}
                </div>
              </div>
            </div>

            <div className="col-md-4 services ftco-animate">
              <div className="d-block d-flex">
                <div className="icon d-flex justify-content-center align-items-center">
                  {/* <span className="flaticon-tow-truck text-primary"></span> */}
                  <img src={pull} width="50%" />
                </div>
                <div className="media-body pl-3">
                  <h3 className="heading">Tow Truck</h3>
                  <p>
                    Experience peace of mind with our reliable tow truck
                    services, ensuring your vehicle is safely transported
                    anytime, anywhere.
                  </p>
                  {/* <p>
                    <a href="#" className="btn-custom">
                      Read more
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="d-block d-flex">
                <div className="icon d-flex justify-content-center align-items-center">
                  {/* <span className="flaticon-repair text-primary"></span> */}
                  <img src={maintaineance} width="50%" />
                </div>
                <div className="media-body pl-3 mt-auto ">
                  <h3 className="heading">Car Maintenance</h3>
                  <p>
                    Keep your car running smoothly and efficiently with our
                    expert maintenance services-ensuring safety, reliability,
                    and longevity.
                  </p>
                  {/* <p>
                    <a href="#" className="btn-custom">
                      Read more
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
