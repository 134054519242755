import Section from "./main";
import Footer from "./footer";
import Reviews from "./reviews";
import Booking from "./booking";
import AboutUs from "./aboutus";
import Facts from "./facts";
import Services from "./services";

const HomePage = () => {
  return (
    <>
      <Section />
      <AboutUs />
      <Services />
      <Facts />
      <Booking />
      <Reviews />
      <Footer />
    </>
  );
};

export default HomePage;
