import "./App.css";
import { HashRouter, Routes, Route, Link } from "react-router-dom";
import Logo from "./icon/images/Logo.jpeg";
import AboutUs from "./view/aboutus";
import Services from "./view/services";
import Booking from "./view/booking";
import Footer from "./view/footer";
import Preloader from "./view/preloader";
// import NavBar from "./view/header";
import Whatsapp from "./view/whatsapp";
import HomePage from "./view/home";

function App() {
  return (
    <div className="App">
      <Preloader />

      <HashRouter>
        {/* <!-- Topbar Start --> */}
        <div className="container-fluid bg-light p-0">
          <div className="row gx-0 d-none d-lg-flex">
            <div className="col-lg-7 px-5 text-start">
              <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                <small className="fa fa-map-marker-alt text-primary me-2"></small>
                <small>Triumph Tower MG Road,Shanthala Nagar, Bangalore</small>
              </div>
              <div className="h-100 d-inline-flex align-items-center py-3">
                <small className="far fa-clock text-primary me-2"></small>
                <small>Mon - Fri : 09.00 AM - 09.00 PM</small>
              </div>
            </div>
            <div className="col-lg-5 px-5 text-end">
              <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                <small className="fa fa-phone-alt text-primary me-2"></small>
                <small>+91-8217307139</small>
              </div>
              <div className="h-100 d-inline-flex align-items-center">
                <a
                  className="btn btn-sm-square bg-white text-primary me-1"
                  href="https://www.facebook.com/artoies/"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-sm-square bg-white text-primary me-1"
                  href="https://x.com/myartoies"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-sm-square bg-white text-primary me-1"
                  href="https://in.linkedin.com/company/artoies-interational-private-limited  "
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  className="btn btn-sm-square bg-white text-primary me-0"
                  href="https://www.instagram.com/artoies/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Topbar End --> */}

        {/* <!-- Navbar Start --> */}
        <nav className="navbar navbar-expand-md bg-secondary  navbar-light shadow relative-top p-0">
          <Link
            to="/"
            className="navbar-brand d-flex align-items-center px-4 px-lg-5"
          >
            <h2 className="m-0 text-primary">
              <img src={Logo} alt="company-logo" width="150px" />
            </h2>
          </Link>
          <button
            type="button"
            className="navbar-toggler bg-light m-2 border-5 rounded-circle"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            // style={{ width: "50px", height: "40px" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0 m-2">
              <Link to="/" className="nav-item nav-link text-white icon-link">
                Home
              </Link>
              <Link to="/aboutus" className="nav-item nav-link text-white">
                About
              </Link>
              <Link to="/services" className="nav-item nav-link text-white">
                Services
              </Link>
              <Link to="/booking" className="nav-item nav-link text-white">
                Booking
              </Link>
            </div>
            <a
              href="https://wa.me/918217307139"
              target="_blank"
              className="btn btn-primary btn-sm py-4 px-lg-5 d-none d-lg-block"
            >
              Get A Quote Contact us<i className="fa fa-arrow-right ms-3"></i>
            </a>
          </div>
        </nav>
        {/* <!-- Navbar End --> */}
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route
            exact
            path="/aboutus"
            element={
              <div>
                <AboutUs />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/services"
            element={
              <div>
                <Services />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/booking"
            element={
              <div>
                <Booking />
                <Footer />
              </div>
            }
          />
        </Routes>
      </HashRouter>
      <Whatsapp />
    </div>
  );
}

export default App;
